import React from "react";
import '../hojas-de-estilo/person.css';


function Mes (props){
    return (
        <div className="mes-contenedor"> `MONTH {props.mes}`
        <h1> hola estoy adentro </h1>
        {console.log("estoy adentro")}
        
        </div>
        

    );
};

export default Mes;