import React from "react";
import '../hojas-de-estilo/person.css';


function Person(props){
    
    return(

        
        <div
            className="contenedor-person">   
            <img
                className="imagen-persona"
                src={require(`../imagenes/${props.imagen}-imagen.jpg`)} 
                alt={`Foto de ${props.imagen}`}/>
        
        <div className="contenedor-informacion">
            <p className="nombre-persona"> {props.nombre} </p>
            <p className="horas-persona">Worked hours: {props.horas} </p>
            <p className="tips-persona"> Tips: {props.tips}</p>
        </div>

        </div>
                       
    );              
};

export default Person;