import Person from './components/person';
import Mes from './components/mes';
import './App.css';
import SteinStore from "stein-js-client";
import { useEffect, useState } from 'react';
import { Item } from 'semantic-ui-react';
import './hojas-de-estilo/icono.css';




function App() {

    const [personas, setPersonas] = useState([]);


    let mesPlantilla = ['March', 'April'];
  
    useEffect(() => {
      let didCancel = false;

      async function fetchPersona(){
        if (!didCancel){
          var team = [];

          var store = new SteinStore("https://api.steinhq.com/v1/storages/64393488eced9b09e9c99a78/");   
          let respuesta = await store.read(meses[mes], { limit: 0, offset: 0 });

          let dias_del_mes = Object.keys(respuesta[1]);

          dias_del_mes = dias_del_mes.filter(nodia => nodia != 'name' && nodia !=  'total_tips');

          let personas = []


          for(let dia=1;dia<=dias_del_mes.length;dia++){

          //console.log("dia: " + dia);
          let team = []; 
          let tips = 0;
          let horas = [];
          let list_tips = [];
          
          for(let i=0;i<respuesta.length;i++){
              let a = respuesta[i][dia];
              
              if(trabajando(a) == true){
                  team.push(respuesta[i].name);
                  horas.push(fromStringToHour(respuesta[i][dia]));
                  addPersona(personas,respuesta[i].name)
                  tips = respuesta[0][dia];

              } 
          }
          list_tips = repartiendoTips(horas,tips*0.79);

          agregandoTips(personas, team, list_tips, dia)
          //console.log(team)
          //console.log(list_tips);
          }
          
          setPersonas(personas)

          //         FUNCIONES

          function agregandoTips(personas, team, tips, dia,){
          
          for(let i=0;i<team.length; i++){
              for(let j=0;j<personas.length; j++){
              if (team[i]==personas[j].name){
                  personas[j][dia] = parseFloat(tips[i]["tips"]);
                  personas[j]["tipsDelMes"] += parseFloat(tips[i]["tips"]);
                  personas[j]["horasDelMes"] += parseFloat(tips[i]["horas"])
              }
              }
          }

          }

          function trabajando(horario){
          let s = horario;

          if(typeof s == "string" && s.slice(-2) == "MA"){
              return true;
          } else{
              return false;
          }
          }

          function addPersona(personas, nombre){
          let persona = new Object();
          persona["name"] = nombre;
          persona["tipsDelMes"] = 0;
          persona["horasDelMes"] = 0;
          let adentro = false;
          
          for(let i=0; i<personas.length; i++){
              if(personas[i].name == nombre){
              adentro = true;
              } 
          }
          if(adentro == false){
              personas.push(persona)
          }    
          }

          function fromStringToHour(horario){
              let hour = horario.substring(0,2);
              let hora = 22 - parseInt(hour);

                  if(horario.substring(2,4) == "30"){
                  return hora = hora - 0.5; 
                  } else {
                  return hora;
                  }
          }


          function repartiendoTips(horas, tips){
              let list_tips = [];
              let horas_totales = 0
              
              for (const hora of horas) {
              horas_totales += hora;
              }

              for(let i=0;i<horas.length;i++){
              let info_dia = new Object();
              info_dia["tips"] = (horas[i]*tips/horas_totales).toFixed(2);
              info_dia["horas"] = horas[i];
              list_tips.push(info_dia);
              }

              return list_tips
          }
        }
      }

      let meses = ["March23","April23"]
      let mes = 0; 
      

      const btnAnterior = document.getElementById('btnAnterior');
      const btnSiguiente = document.getElementById('btnSiguiente');

      btnSiguiente.addEventListener('click', () =>{
        if(mes < 1){
          mes += 1;
          
          fetchPersona();
        }
      });

      btnAnterior.addEventListener('click', () =>{
        if(mes > 0){
          mes -= 1;
          
          fetchPersona();
        }
      });

    

    },[]);
  
      

  return (
    <div className="App">
      <div className='contenedor-principal'>
        <div><h1 className='titulo'>TIPS ALEBRIJES MANIFESTO</h1></div>
       
        
        <div className='personas'>
          { personas.map((item) => (
            <Person 
            imagen = {item.name}
            nombre = {item.name}
            horas = {item.horasDelMes}
            tips = {(item.tipsDelMes).toFixed(2)} />
          ))}
        </div>  

        <div className='paginacion'>
            <button id="btnAnterior">Anterior</button>
            <button id="btnSiguiente">Siguiente</button>
        </div>
      
          
      </div>
    </div>
    );
}

export default App;
